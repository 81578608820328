import styled from "styled-components";
import { Colors, Rem } from "../../commons/Theme";

export const StyledCrowdPlans = styled.div`
  text-align: center;
`;

export const StyledTitle = styled.div`
  font-size: ${Rem(24)};
  line-height: ${Rem(32)};
  color: ${Colors.yellow};
`;

export const StyledSubtitle = styled.div`
  font-size: ${Rem(24)};
  line-height: ${Rem(32)};
`;

export const StyledPlansContainer = styled.div`
    margin: ${Rem(40)} auto ${Rem(20)};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

`;
